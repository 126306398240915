import React, { useState } from 'react';
import Sharepoint from './Pages/Sharepoint';
import Dropbox from './Pages/Dropbox';

function App() {

  const [syncType, setSyncType] = useState('Sharepoint');

  const createFetch = () => {
    // Create a cache of fetches by URL
    const fetchMap = {};

    return (url, options) => {
        // Check to see if its not in the cache otherwise fetch it
        if (!fetchMap[url]) {
            fetchMap[url] = fetch(url, options).then((res) => (res.type == JSON) ? res.JSON : "");
        }
    
        // Return the cached promise
        return fetchMap[url];
    };
  };

  return (
    <>
      
      {syncType === 'Sharepoint' ? <Sharepoint createFetch={createFetch} setSyncType={setSyncType} syncType={syncType} /> : <Dropbox createFetch={createFetch} setSyncType={setSyncType} syncType={syncType} /> }
    </>
  );
 
}

export default App;
