import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const SyncTypeDropdown = ({ syncType,  setSyncType}) => {

    const handleSyncTypeChange = (e, type) => {
        setSyncType(e);
        // console.log(e);
    };

    return (
        <DropdownButton title={syncType} onSelect={(e, type) => handleSyncTypeChange(e, type)}>
            <Dropdown.Item eventKey="Dropbox">Dropbox</Dropdown.Item>
            <Dropdown.Item eventKey="Sharepoint">Sharepoint</Dropdown.Item>
        </DropdownButton>
    )
}

export default SyncTypeDropdown;