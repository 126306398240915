import '../Styling/style.css';
import React, { useState } from 'react';
import PlusImage from '../Assets/plus.svg';
import MinusImage from '../Assets/minus.svg';
import { useEffect } from 'react';

const ApiContainer = ( {setFilevineSecret_1, setFilevineSecret_2, setFilevineSecret_3, setFilevineSecret_4, setFilevineSecret_5, 
    setApiKey_1, setApiKey_2, setApiKey_3, setApiKey_4, setApiKey_5} ) => {


    
    const inputKeyArr = [
        {
            label: "Api Key 1",
            className: "text-input",
            type:"text", 
            name:"apiKey_1",
            id:1,
            value: "",
            key:1
        }
        
    ];

    const inputSecretArr = [
        {
            label: "Filevine Secret 1",
            className: "text-input",
            type:"text", 
            name:"FilevineSecret_1",
            id: 1,
            value: "",
            key:1
        }
    ]

    const combinedInput = [
        {
            key:1,
            type:"text", 
            value: ""
        },
        {
            key:1,
            type:"text", 
            value: ""
        }
    ]

    const [keyArr, setKeyArr] = useState(inputKeyArr)
    const [secretArr, setSecretArr] = useState(inputSecretArr)
    const [combinedArr, setCombinedArr] = useState([combinedInput]);

    const addInput = (e) => {
        e.preventDefault();

        if (keyArr.length == 5) {
            return;
        }
        setKeyArr(s => {
            return [
            ...s,
            {
                type: "text",
                value: ""
            }
            ];
        });
        setSecretArr(s => {
            return [
            ...s,
            {
                type: "text",
                value: ""
            }
            ];
        });

        setCombinedArr(s => {
            return [
            ...s,
                [
                    {
                        type: "text",
                        value: ""
                    },
                    {
                        type: "text",
                        value: ""
                    }
                ]
            ];
        });
    };

    const removeInput = (e) => {
        e.preventDefault();
        if (keyArr.length == 1) {
            return;
        }
        var tempArr1 = keyArr;
        tempArr1.pop();
        setKeyArr(tempArr1 => {
            return [...tempArr1];
        });


        var tempArr2 = secretArr;
        tempArr2.pop();
        setSecretArr( tempArr2 => {
            return [...tempArr2];
        });

        var tempArr3 = combinedArr;
        tempArr3.pop()
        setCombinedArr( tempArr3 => {
            return [...tempArr3];
        });

    };


    const handleChangeKey = (e, i) => {
        e.preventDefault();
    
        const index = i;
        setKeyArr(s => {
          const newArr = s.slice();
          newArr[index].value = e.target.value;
          return newArr;
        });
        setCombinedArr(s => {
            const newArr = s.slice();
            newArr[index][0].value = e.target.value;
            return newArr;
        })
        const adjustedIndex = index+1;

        if (e.target.value == null) {
            return;
        }

        if (adjustedIndex == 1) {
            setApiKey_1(e.target.value);
        }
        else if (adjustedIndex == 2) {
            setApiKey_2(e.target.value);
        }
        else if (adjustedIndex == 3) {
            setApiKey_3(e.target.value);
        }
        else if (adjustedIndex == 4) {
            setApiKey_4(e.target.value);
        }
        else if (adjustedIndex == 5) {
            setApiKey_5(e.target.value);
        }

    };

    const handleChangeSecret = (e, i) => {
        e.preventDefault();
    
        const index = i;
        setSecretArr(s => {
          const newArr = s.slice();
          newArr[index].value = e.target.value;
          return newArr;
        });
        setCombinedArr(s => {
            const newArr = s.slice();
            newArr[index][1].value = e.target.value;
            return newArr;
        })
        const adjustedIndex = index+1;

        if (e.target.value === null) {
            return;
        }

        if (adjustedIndex === 1) {
            setFilevineSecret_1(e.target.value);
        }
        else if (adjustedIndex === 2) {
            setFilevineSecret_2(e.target.value);
        }
        else if (adjustedIndex === 3) {
            setFilevineSecret_3(e.target.value);
        }
        else if (adjustedIndex === 4) {
            setFilevineSecret_4(e.target.value);
        }
        else if (adjustedIndex === 5) {
            setFilevineSecret_5(e.target.value);
        }
    };

    return (
        <div className="api-container">
            {combinedArr.map((item, i) => {
                if (i >= 5) {
                    return (<></>);
                }
                return (
                    <div key={i}>
                        <div key={"api " + i} className="input-container">
                            <label key={"api label " + i}>Filevine API Key {i+1}</label>
                            <div key={"api div " + i}>
                                <input className="text-input" key={"api text " + i} onChange={(e) => handleChangeKey(e, i)} value={item[0].value} type={item[0].type} required={true} />
                            </div>
                        </div>
                        <div key={"secret " + i} className="input-container">
                            <label key={"secret label " + i}>Filevine API Secret {i+1}</label>
                            <div key={"secret div " + i}>
                                <input className="text-input" key={"secret text " + i} onChange={(e) => handleChangeSecret(e, i)} value={item[1].value} type={item[1].type} required={true} />
                            </div>
                        </div>
                    </div>
                );
            })}

            <button className="api-button">
                <img src={PlusImage} className='api-img'  onClick={addInput} />
                <img src={MinusImage} className='api-img-minus'  onClick={removeInput} />

            </button>

        </div>
    );
};

export default ApiContainer;