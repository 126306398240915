import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import React from "react";
import PropTypes from 'prop-types';
import Modal from '../Components/Modal.js';
import helpImage from '../Assets/help.svg'
import '../Styling/style.css';
import ApiContainer from "../Components/ApiContainer.js";
import TestApiKey from "../Components/TestApiKey.js";
import SyncTypeDropdown from '../Components/SyncTypeDropdown';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dropbox = ({ createFetch, setSyncType, syncType }) => {
    const [errorMessages, setErrorMessages] = useState({});
    const [orgId, setOrgId] = useState("");
    const [email, setEmail] = useState("");
    const [domain, setDomain] = useState("");
    const [dropboxSecret, setDropboxSecret] = useState("");
    const [dropboxApiKey, setDropboxApiKey] = useState("");
    const [apiKey_1, setApiKey_1] = useState("");
    const [apiKey_2, setApiKey_2] = useState("");
    const [apiKey_3, setApiKey_3] = useState("");
    const [apiKey_4, setApiKey_4] = useState("");
    const [apiKey_5, setApiKey_5] = useState("");
    const [filevineSecret_1, setFilevineSecret_1] = useState("");
    const [filevineSecret_2, setFilevineSecret_2] = useState("");
    const [filevineSecret_3, setFilevineSecret_3] = useState("");
    const [filevineSecret_4, setFilevineSecret_4] = useState("");
    const [filevineSecret_5, setFilevineSecret_5] = useState("");

    useEffect(() => {
        setErrorMessages({});
        setOrgId("");
        setEmail("");
        setDomain("");
        setApiKey_1("");
        setApiKey_2("");
        setApiKey_3("");
        setApiKey_4("");
        setApiKey_5("");
        setFilevineSecret_1("");
        setFilevineSecret_2("");
        setFilevineSecret_3("");
        setFilevineSecret_4("");
        setFilevineSecret_5("");
        setDropboxApiKey("");
        setDropboxSecret("");
    }, [syncType, setSyncType])

    const [modalOpen, setModalOpen] = useState(false);
    const myFetch = createFetch()

    // base URL for the API
    const BASE_API_URL = 
    (window.location.href.includes("localhost") ? "https://localhost:7070/api" : "/api");

    // notifies the user if the guid is not in the url
    const handleNotify = () => {
        // if guid si not in the URL
        if (window.location.href.indexOf("guid") === -1) {
            toast("The GUID is missing!");
            return;
        }
    }

    // for modal (the ? at the bottom)
    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    // render error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
    );

    // handle form submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        /*
            Get GUID from URL
        */
        var CurrentURL = window.location.href;

        var guid_starting_index = CurrentURL.indexOf("guid=");

        // if -1, the guid is missing
        // do not proceed if this is the case
        if (guid_starting_index === -1) {
            return;
        }

        guid_starting_index+=5;

        // get the guid from the current url
        const GUID = CurrentURL.substring(guid_starting_index);
        sessionStorage.setItem("guid", GUID);
        var URL = "";

        /*
            Make POST request to API 
        */
        
        var response = await fetch(BASE_API_URL + '/initialsignupdropbox', {

            method: 'POST',
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                "GUID": GUID,
                "Email": email,
                "Domain": domain,
                "OrgID": orgId,
                "ApiKey_1": apiKey_1,
                "ApiKey_2": apiKey_2,
                "ApiKey_3": apiKey_3,
                "ApiKey_4": apiKey_4,
                "ApiKey_5": apiKey_5,
                "FilevineSecret_1": filevineSecret_1,
                "FilevineSecret_2": filevineSecret_2,
                "FilevineSecret_3": filevineSecret_3,
                "FilevineSecret_4": filevineSecret_4,
                "FilevineSecret_5": filevineSecret_5,
                "DropboxApiKey": dropboxApiKey,
                "DropboxSecret": dropboxSecret
            })
        }).then(response => response.text())
        .then(data => URL = data.toString());

        // if there is an error, let the user know what happened
        if (URL.includes("Error")) {
            toast(URL);
            
        }
        else {
            toast("Your entry has been recorded. Thank you.");
        }


    };

    
    
    return (
        <div className="container">
            {/* <div className="image-container"></div> */}
            <img className="image-container" src="https://fuel.digital/wp-content/uploads/2022/06/fuel-digital-logo.svg" />
            <div className="screen-container">
                <div className="screen">
                    <div className="screen-content">
                        <form className="input-form" onSubmit={handleSubmit}>
                            <div className="input-inner-form">
                                <div className="input-container-dropdown">
                                    <SyncTypeDropdown syncType={syncType} setSyncType={setSyncType} />
                                </div>
                                <div className="input-container">
                                    <label>OrgId</label>
                                    <div>
                                        <input className="text-input "type="text" name="OrgId" value={orgId} onChange={(e) => setOrgId(e.target.value)} required />
                                        {renderErrorMessage("orgid")}
                                    </div>
                                    
                                </div>
                                <div className="input-container">
                                    <label>Filevine API Domain</label>
                                    <div>
                                        <input className="text-input" type="text" name="Domain" onChange={(e) => setDomain(e.target.value)} required />
                                        {renderErrorMessage("domain")}
                                    </div>
                                </div>
                                <div className="input-container">
                                    <label>Email</label>
                                    <div>
                                        <input className="text-input" type="text" name="Email" onChange={(e) => setEmail(e.target.value)} required />
                                        {renderErrorMessage("email")}
                                    </div>
                                </div>

                                <div className="input-container">
                                    <label>Dropbox API Key</label>
                                    <div>
                                        <input className="text-input" type="text" name="dropboxApiKey" onChange={(e) => setDropboxApiKey(e.target.value)} required />
                                        {renderErrorMessage("Dropbox API key")}
                                    </div>
                                </div>

                                <div className="input-container">
                                    <label>Dropbox Secret</label>
                                    <div>
                                        <input className="text-input" type="text" name="dropboxSecret" onChange={(e) => setDropboxSecret(e.target.value)} required />
                                        {renderErrorMessage("Dropbox secret")}
                                    </div>
                                </div>
                                
                                <ApiContainer 
                                setFilevineSecret_1={setFilevineSecret_1} setFilevineSecret_2={setFilevineSecret_2} setFilevineSecret_3={setFilevineSecret_3} setFilevineSecret_4={setFilevineSecret_4} setFilevineSecret_5={setFilevineSecret_5} 
                                setApiKey_1={setApiKey_1} setApiKey_2={setApiKey_2} setApiKey_3={setApiKey_3} setApiKey_4={setApiKey_4} setApiKey_5={setApiKey_5}/>

                                <div className="button-container">
                                    <button className="button" type="submit" value="Submit" onClick={handleNotify}>
                                        Submit
                                    </button>
                                    <ToastContainer />
                                </div>

                                <br />
                            </div>
                        </form>
  
                        <Modal isOpen={modalOpen} handleClose={closeModal} />
                        
                    </div>
                </div>
            </div>
            <br />
            {/* <TestApiKey BASE_API_URL={BASE_API_URL} createFetch={createFetch}/> */}

            <button className="help-button" type="button">
                <img className="help-container" onClick={openModal} src={helpImage} alt="Help" />
            </button> 
            <br />
            <br />
            <br />
        </div>
    );

};

export default Dropbox;
