import React from 'react';
import { Link } from 'react-router-dom';
import '../Styling/style.css';

const Modal = ({ isOpen, handleClose, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className='modal-overlay'>
            <div className='modal-content'>
                {children}
                <h1>Instructions</h1>

                <br />
                <br />

                <h3>OrgID</h3>
                <p>
                    To get your OrgID, go to your Filevine accont --&gt; Setup --&gt; Orgs --&gt; ID
                </p>

                <br />
                <br />

                <h3>Filevine API Domain</h3>
                <p>
                    Your Filevine API domain. Examples: 
                    <>"https://api.filevine.io" or </>
                    <>"https://company-name-here.api.filevineapp.com"</>
                </p>

                <br />
                <br />

                <h3>Email</h3>
                <p>
                    The email associated with Filevine.
                </p>

                <br />
                <br />

                <h3>API Keys / Secrets</h3>
                <p>
                    Your Org Admin should have access to this feature within Filevine. A new key can be setup by following the
                    instructions <a href="https://support.filevine.com/hc/en-us/articles/13644331859611-API-Credentials-" target="_blank">here</a>. (If the Org Admin does not have this access, you may need to reach out to your Filevine 
                    representative.)
                </p>
                <br />
                <p>
                    We require at least 1 API Key to perform the document sync, but every additional API key provided 
                    decreases the time required for the initial sync, so we recommend using u to 3. Once the initial sync 
                    is completed, only 1 key will be needed for ongoing syncs.
                </p>

                <br />
                <br />


                <div className='modal-close-button' onClick={handleClose}>X</div>
            </div>
        </div>
    );
}

export default Modal;